import React from "react";
import "./recognition.css";
import recgnitionLogoExp from '../../../assets/images/recgnitionLogoExp.svg'
import RecgnitionLogo from '../../../assets/images/recgnitionLogo.svg'


function Recognition() {
    return (
        <div id="recognition" className="max-width-layout">
            <div className='row '>
                <div className='col-xl-6 col-lg-6 col-md-3 col-sm-12 col-12 m-0 text recognition-image-container'>
                    <img src={RecgnitionLogo} alt="Profiler" className='recognition-image' />
                    <img src={recgnitionLogoExp} alt="Profiler" className='recognition-image' />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-0 recognition-container'>
                    <p>Heart Print is honoured to have received the iF Design Award 2024, recognized as a comprehensive solution that offers a seamless user experience. </p>
                    <p>This accolade celebrates our commitment to bridging the gap between wellness and clinical health, providing users with an integrated platform that supports their health journey. </p>
                </div>
            </div>
        </div>

    );
}

export default Recognition;