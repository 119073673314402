import React from "react";
import "./termsConditionModal.css";


function TermsConditionModal() {
    return (
        <div className="modal fade" id="termsConditionModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <h2 className="modal-title fs-5" id="exampleModalLabel">PHILIPS GHS APP | TERMS OF USE</h2>
                        <p>PLEASE READ THESE TERMS OF USE CAREFULLY AND IN THEIR ENTIRETY, BEFORE ACCESSING OR USING THE GUIDED HEALTH SERVICE APP</p>
                        <p>This user agreement ("<b>Agreement</b>") is an agreement between you and Philips Global Business Services LLP ("<b>Philips</b>"
                            or "<b>we</b>" or "<b>us</b>" as the context requires) governing your use of the Guided Health Service  application
                            ("<b>App</b>") and any other related software and/or services with the characteristics and features as described
                            or available on the App (referred to collectively as the "<b>Services</b>"). Philips may have subsidiaries and
                            affiliated legal entities around the world ("<b>Subsidiaries and Affiliates</b>"), providing the Services to you
                            on behalf of Philips. You acknowledge and agree that the Subsidiaries and Affiliates will be entitled to provide the
                            Services to you under the terms of this Agreement and shall have all rights and privileges equivalent to Philips
                            hereunder, to the extent applicable.</p>
                        <p>This Agreement and the terms and conditions hereunder represent a binding contract between you and us with regard to
                            the App and in particular, the Services. Therefore, you should read this Agreement carefully and, in its entirety,
                            before you start to use the App and/or any Services. You indicate, at your option, your agreement to this Agreement
                            and the terms and conditions hereunder, by clicking or tapping on a button indicating your acceptance of and
                            agreement to this Agreement and the terms and conditions hereunder, or by registering on the App and/or the Services
                            and thereafter using them as a member or guest. This Agreement governs your access and use of the App and the
                            Services and applies to all visitors, users and others who access the Service ("<b>Users</b>"). If you do not
                            agree with the terms contained in this Agreement, you are not permitted to use this App and/or avail the Services.
                            Philips will not be liable for any consequences arising from your unauthorized use.</p>
                        <p>We may revise these terms of use at any time by amending this page and the terms hereof, including pursuant to any
                            changes in applicable laws, rules, or regulations. The revised terms of use shall be posted on the App, and you are
                            expected to check this page from time to time to take notice of any changes we make, as they are binding on you.
                            Some of these provisions may be superseded by provisions or notices published elsewhere on our App. All changes are
                            effective as soon as we post them and by continuing to use the App and avail of the Services, you agree to be bound
                            by the revised terms and conditions of use. Your use of the App and/or the Services is subject to the most current
                            version of the terms of use and this Agreement posted on the App at the time of such use. Depending on the Services
                            you use, you may be subject to certain additional specific terms and conditions ("<b>Additional Terms</b>"), which
                            Philips may notify you of from time to time. Such Additional Terms are automatically incorporated into this
                            Agreement and form an integral part of your agreement with us.</p>
                        <p>Philips has created this App to provide Users, the Services which includes, Guidables and Events. While the App may
                            consider several factors specific to each individual, including anthropometric data, medical and personal
                            information and lifestyle factors, we are not a medical organization, and the suggestions on the App should not be
                            misconstrued as medical advice, prescriptions, or diagnoses (please see the disclaimers set out in Paragraph 7 for
                            further details).</p>
                        <ol>
                            <li><b>ACCESS</b>
                                <ol>
                                    <li>Access to the Services is permitted on a temporary basis, and we reserve the right to withdraw or amend
                                        the Service (or any features within the Services) that we provide on our App by notifying the same on
                                        our App from time to time without prior notice to you. We will not be liable, if for any reason our App
                                        or the Services (or any features within the Services) are unavailable at any time or for any period.
                                        From time to time, we may, at our sole discretion, restrict access to some parts of our App and/or
                                        Services, or our entire App and/or Services, to Users who have registered with us.</li>
                                    <li>Philips grants you permission to use the Services as set forth in this Agreement, provided that you
                                        comply with the terms and conditions of this Agreement, and the Privacy Terms, and use the App and
                                        Services as directed by Philips from time-to-time.</li>
                                    <li>In order to access the Services, you will need to register on the App and create a 'member' account.
                                        Your account gives you access to the Services and functionality that we may establish and maintain from
                                        time to time and in our sole discretion on the App.</li>
                                    <li>By using the Services and completing the registration process, you represent and warrant that: (i) all
                                        the data provided by you is accurate and complete; (ii) you shall maintain the accuracy of such
                                        information, and any changes thereto by regular updation of any such information; (iii) you are over 18
                                        (eighteen) years of age and are fully able and competent to enter into the terms, conditions,
                                        obligations, affirmations, representations, and warranties set forth in this Agreement, and to abide by
                                        and comply with this Agreement; (iv) you are eligible in accordance with applicable laws to enter into a
                                        binding contract and are not a person barred from receiving the Services under applicable laws; and (v)
                                        you shall use the App or such other Services provided through the App only in compliance with this
                                        Agreement and all applicable local, state, national, and international laws, rules and regulations. We
                                        shall not be liable for any injury, damage or other consequence, health related or otherwise arising out
                                        of any inaccuracy in the information provided by you on the App. Your profile may be deleted by us by
                                        informing you 24 (twenty four) hours in advance without warning, or without any notice whatsoever, if we
                                        believe that you have violated any of the terms and conditions as mentioned under this Agreement or the
                                        Privacy Terms published by Philips at Privacy Terms (as may be amended or updated from time to time).
                                    </li>
                                    <li>You must provide your full legal name, valid email address, age, phone number, and any other information
                                        requested from you, from time to time, in order to complete the registration process and be entitled to
                                        use the Services. The information provided by you may be shared by us with Philips’ Subsidiaries and
                                        Affiliates, or any third party for providing the Services, record keeping purposes, internal procedures
                                        or for any other purposes and by using this App and/or availing the Services you expressly consent to
                                        such sharing of the information provided by you.</li>
                                    <li>Upon completing the registration process, you will be provided with a username, password or any other
                                        piece of information, as part of our security procedures. You must treat such information as
                                        confidential, and you must not disclose it to any third party. You also agree to ensure that you
                                        exit/log out from your account at the end of each session. We have the right to disable any username or
                                        password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to
                                        comply with any of the provisions of this Agreement. You will immediately notify Philips of any
                                        unauthorized use of your password or username, by sending details of such unauthorized use to:
                                        <b>ghs.support@philips.com</b></li>
                                    <li>You are responsible for making all arrangements necessary for you to have access to our App and thereby
                                        the Services. You are also responsible for ensuring that any persons who access our App and thereby the
                                        Services through your account are aware of these terms, and that they comply with them. You are also
                                        solely responsible and liable to Philips for all activities that take place or occur under your account.
                                        You agree that your ability to log into your account is dependent upon external factors such as internet
                                        service providers and internet network connectivity and we shall not be liable to you for any damages
                                        arising from your inability to log into your account.</li>
                                    <li>Philips reserves the right to refuse access to use the Services offered at the App to new Users or to
                                        terminate access granted to existing Users at any time without according any reasons for doing so. Use
                                        of the account is not available to any User who is suspended or prohibited by Philips from using the App
                                        or Services for any reason whatsoever.</li>
                                </ol>
                            </li>
                            <li><b>MEMBERSHIP/REGISTRATION FOR THE SERVICES</b>
                                <ol>
                                    <li>Once you complete registration on the App, you will be a member which: <ol>
                                        <li>allows you to access content, and provide information and data, on the App;</li>
                                        <li>allows us to contact you, if necessary, in order to inform you of changes to the terms of use of
                                            the App or describing new services that we enable on the App;</li>
                                        <li>allows you to make use of the Services on the App subject to these terms and conditions;</li>
                                        <li>allows you to access and purchase additional Services on the App, subject to the terms and
                                            conditions set out herein; and/or</li>
                                        <li>does not allow you to use email addresses or other contact details displayed on the App for any
                                            purpose other than that specifically authorized by us.</li>
                                    </ol>
                                    </li>
                                    <li>By providing Philips your email address and phone number, you expressly consent to: <ol>
                                        <li>Our using your email address or mobile number to send you Service-related notices, including any
                                            notices required by law, in lieu of communication by postal mail. You may use your settings to
                                            opt out of Service-related communications vide email/mobile number or entirely.</li>
                                        <li>Our using the phone numbers provided by you, to contact you from time to time, in order to
                                            provide you updates and advice relating of your progress on the App and the usage of our
                                            Services, and relating to any new promotions and sales opportunities on the App.</li>
                                        <li>Our using your email address or phone number to send you other messages, including changes to
                                            features of the Service and special offers. If you do not want to receive such messages, you may
                                            opt out by sending us an email at: <b>ghs.support@philips.com</b>. Opting out may prevent you
                                            from receiving email messages or phone messages regarding updates, improvements, or offers.</li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><b>PRIVACY AND YOUR PERSONAL INFORMATION</b>
                                <p>For information about Philips’ data protection practices in relation to the App and the Services,please read
                                    the Privacy Terms set out at Privacy Terms.The Privacy Terms explain how Philips treats your personal
                                    information, and protects your privacy, when you use the App and the Services. The terms of the Privacy
                                    Terms are incorporated into, and form an integral and crucial part of, this Agreement and the terms and
                                    conditions hereunder.</p>
                            </li>
                            <li><b>RESTRICTION ON USE OF APP AND SERVICES</b>
                                <p>You are not permitted to and shall not do any of the following acts, or any similar acts, in any manner
                                    either directly or indirectly, the breach of which shall entitle Philips to terminate your use of the
                                    Services, without prejudice to any other rights or remedies we may have against you:</p>
                                <ol>
                                    <li>Distribute, sell, resell, rent, lease, supply, modify, sublicense, or otherwise transfer, or use the App
                                        to provide time sharing or similar services for any third party, make any copies of, adapt, amend,
                                        incorporate, merge or otherwise alter the App or Services and all content provided to you as part of the
                                        App or Services; </li>
                                    <li>Remove, circumvent, disable, damage or otherwise interfere with security-related features of the App,
                                        features that prevent or restrict use or copying of any content accessible through the App, or features
                                        that enforce limitations on use of the App; or delete the copyright and other proprietary rights notices
                                        on the App;</li>
                                    <li>Attempt to decompile, reverse engineer or otherwise disassemble or reduce the App or any Service or
                                        content provided to you as part of the Services;</li>
                                    <li>Attempt to copy any software provided to you as part of the Services or the App and in particular its
                                        source code, or attempt to decrypt any part of such software that is provided to you;</li>
                                    <li>Create any derivative work or version of any software provided by us in relation to or to facilitate
                                        your use of the Services or any content provided as part of the Services;</li>
                                    <li>Remove, from the Services or any content provided as part of the Services, any logo, copyright or
                                        proprietary notices, legends, symbols, labels, trademarks, watermarks, signatures or any other like
                                        marks affixed to or embedded in the Services;</li>
                                    <li>Create or operate more than 1 (one) account or registration for membership, per User, on the App;</li>
                                    <li>Use the Services or any part of it to create a false identity, to impersonate any person or
                                        organization, or attempt to disguise the origin of any content;</li>
                                    <li>Use any of Philips' domain names as a pseudonymous return email address;</li>
                                    <li>Access or use the App in any manner that could damage, disable, overburden, or impair any of the App's
                                        servers or the networks connected to any of the servers on which the App is hosted;</li>
                                    <li>Access or attempt to access any content that you are not authorized to access by any means;</li>
                                    <li>Access the App through any other means other than through the interface that is provided by us;</li>
                                    <li>Disrupt or interfere with the security of, or otherwise cause harm to the App, materials, system
                                        resources, or gain unauthorized access to the user accounts, passwords, servers or networks connected to
                                        or accessible through the App or any affiliated linked sites;</li>
                                    <li>Use the App or Services in a manner that results in or may result in complaints, disputes, reversals,
                                        chargebacks, fees, fines, penalties and other liability to us, a third party or you;</li>
                                    <li>Use the App or Services to collect or obtain personal information, including without limitation,
                                        financial/confidential information, about other users or visitors; and/or</li>
                                    <li>Infringe our or any third party's intellectual property rights, rights of publicity or privacy; host,
                                        display, upload, modify, publish, post, transmit, update or share any message, data, image or program
                                        which: (i) is illegal or violates or may violate any applicable laws, rules or regulations, (ii) is
                                        libellous, defamatory or which discloses private or personal matters concerning any person, or (iii) is
                                        pornographic in nature, or (iv) threatens the unity, integrity, defence, security or sovereignty of
                                        India, friendly relations with foreign states, or public order or causes incitement to the commission of
                                        any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                                </ol>
                                <p>It is understood and agreed that the above list is not exhaustive and has been provided for illustrative
                                    purposes only.</p>
                            </li>
                            <li><b>RIGHTS RESERVED BY PHILIPS</b>
                                <ol>
                                    <li>We have the right to disclose your identity and personal information to any third party who validly
                                        claims and provides evidence that any material posted or uploaded by you to our App constitutes a
                                        violation of their intellectual property rights, or of their right to privacy.</li>
                                    <li>We are not responsible, or liable to any third party, for the content or accuracy of any materials
                                        posted by you or any other User of the Services.</li>
                                    <li>We have the right to immediately terminate your access or usage rights and remove non-compliant
                                        information or material, in case of non-compliance with these terms and conditions, this Agreement or
                                        the Privacy Terms. </li>
                                    <li>We have the right to investigate and prosecute violations of these terms and conditions to the fullest
                                        extent of the law and may involve and cooperate with law enforcement authorities in prosecuting Users
                                        who violate these terms and conditions, this Agreement or the Privacy Terms.</li>
                                    <li>While we have no obligation to monitor your access to, or your use of, the App or the Services (or any
                                        feature or part thereof), we have the right to do so for the purpose of operating the App and providing
                                        the Services, to ensure your compliance with these terms and conditions, this Agreement or the Privacy
                                        Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or
                                        other governmental body. We reserve the right to access, read, preserve, and disclose any information as
                                        we reasonably believe is necessary to: (a) satisfy any applicable law, regulation, legal process or
                                        governmental request; (b) enforce these terms and conditions, this Agreement or the Privacy Terms,
                                        including investigation of potential violations hereof; (c) detect, prevent, or otherwise address fraud,
                                        security or technical issues; (d) respond to user support requests; or (e) protect the rights, property
                                        or safety of Philips, its Users and the public. Philips shall not be responsible or liable for the
                                        exercise or non-exercise of its rights under these terms and conditions in this regard.</li>
                                    <li>Subject to the receipt of a complaint from any person, third party, and/or authority, we have the right
                                        (but shall have no obligation) to pre-screen, review, flag, filter, modify, refuse or remove any or all
                                        content from any Service. For some of the Services, Philips may (but shall be under no obligation to)
                                        provide tools to filter out any content which it deems to be unsuitable at its sole discretion.</li>
                                    <li>We reserve the right to introduce or change the prices of all paid Services upon 15 (fifteen) days
                                        notice from us. Such notice may be provided at any time by posting the changes to the terms and
                                        conditions or this Agreement or the Services on the App itself.</li>
                                    <li>Philips shall not be liable to you or to any third party for any modification, price change, suspension
                                        or discontinuance of the Services.</li>
                                </ol>
                            </li>
                            <li><b>BREACH OF TERMS</b>
                                <ol>
                                    <li>When we consider that a breach of these terms and conditions has occurred, we may at our discretion take
                                        such action as we deem appropriate. Failure to comply constitutes a material breach of the terms of use
                                        on which you are permitted to use the App and the Services, and we may take any action legally available
                                        including without limitation all or any of the following actions: <ol>
                                            <li>Immediate, temporary or permanent withdrawal of your right to use the Services and simultaneous
                                                termination/suspension of your account on the App;</li>
                                            <li>Immediate, temporary or permanent removal of any contribution already posted on the App using
                                                the Services, upon your failure to cure the breach that is brought to your notice;</li>
                                            <li>Issue of a warning to you;</li>
                                            <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including,
                                                but not limited to, reasonable administrative and legal costs) resulting from the breach;</li>
                                            <li>Further legal action against you; and/or</li>
                                            <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary
                                                or as required by law.</li>
                                        </ol>
                                    </li>
                                    <li>We hereby disclaim and exclude our liability for all action we may take in response to breaches of these
                                        terms and conditions, this Agreement or the Privacy Terms. The actions described above are not limited,
                                        and we may take any other action we reasonably deem appropriate.</li>
                                    <li>Upon such termination/suspension of your account, you will no longer be able to access your account or
                                        any content or data you have stored on the servers. All rights granted to you by Philips in relation to
                                        the App or the Services will automatically terminate.</li>
                                </ol>
                            </li>
                            <li><b>DISCLAIMERS</b>
                                <ol>
                                    <li>You agree and acknowledge that Philips and its Subsidiaries and Affiliates are not engaged in rendering
                                        medical, clinical, or diagnostic advice. If medical or other expert assistance is required, the services
                                        of a competent professional should be sought. No warranties are made regarding the results obtained from
                                        the App and/or the Services (or the accuracy thereof), that all errors in the App and/or the Services
                                        will be corrected, that the App and/or the Services will be uninterrupted, or that the functionality of
                                        the App and/or the Services will be secure, meet User’s requirements or achieve an intended outcome.
                                        User is solely responsible for decisions made and actions taken based on its use of the App and/or the
                                        Services. Philips and its Subsidiaries and Affiliates assume no liability or responsibility for any
                                        loss, damage or injury to any individual (including the User) arising from User’s use of the App and/or
                                        the Services. The content of the App, including without limitation, text, copy, audio, video,
                                        photographs, illustrations, graphics and other visuals, is for informational purposes only and does not
                                        constitute/substitute professional medical advice, diagnosis, treatment or recommendations of any kind.
                                        You should always seek the advice of your qualified heath care professionals with any questions or
                                        concerns you may have regarding your individual needs and any medical conditions. You agree that you
                                        will not under any circumstances disregard any professional medical advice or delay in seeking such
                                        advice in reliance on any content provided on or through the App.</li>
                                    <li>You agree that your use of the App and the Services, and reliance on any content thereof, shall be at
                                        your own risk. You expressly understand and agree that, to the maximum extent permitted by applicable
                                        law, the App and the Services are provided on an “as is” and “as available” basis for your use, without
                                        warranties of any kind, express or implied, including without limitation the warranties of being
                                        accurate or correct, fitness for a particular purpose, title, non-infringement, merchantability, and
                                        those arising from course of dealing or usage. Philips shall use all reasonable endeavours to ensure
                                        that the Services are uninterrupted but it does not guarantee or warrant that: (i) the Services will
                                        meet your specific requirements; (ii) the Services will be uninterrupted, timely, secure, or error-free;
                                        (iii) the results that may be obtained from the use of the Services will be accurate or reliable; and
                                        (iv) the quality of any Products, services, information, or other material purchased or obtained by you
                                        through the Services will meet your expectations; and (v) any errors in the Services will be corrected.
                                        No oral or written information or advice given by Philips and/or its Subsidiaries and Affiliates, or
                                        their employees, directors, distributors, dealers, or agents, will increase the scope of the above
                                        warranties or create any new warranty.</li>
                                    <li>You may avoid using the App if you have any medical history, existing disorders, or underlying
                                        conditions, that may put you at risk, including, without limitation, one or more of the following
                                        conditions. In case you have any such medical history, disorders, or conditions, you are required to
                                        consult a qualified health care practitioner and use the App and Services only based on such
                                        consultation: <ol>
                                            <li>heart disease;</li>
                                            <li>high blood pressure;</li>
                                            <li>family history of high blood pressure or heart disease;</li>
                                            <li>chest pain caused by previous exercise;</li>
                                            <li>dizziness or loss of consciousness caused by previous exercise;</li>
                                            <li>bone or joint problems;</li>
                                            <li>diabetes, high cholesterol, obesity; and/or</li>
                                            <li>arthritis.</li>
                                        </ol>
                                    </li>
                                    <li>You should discontinue any exercise in cases where it causes pain or severe discomfort and should
                                        consult a medical expert prior to returning to exercise in such cases. We reserve the right to deny you
                                        access to the Services for any reason or no reason, including if we determine, in our sole discretion,
                                        that you have certain medical conditions.</li>
                                    <li>The App or Services may provide, or third parties may provide, links to other web sites or resources.
                                        Given that we have no control over such sites and resources, you acknowledge and agree that we are not
                                        responsible for the availability of such external sites or resources, and do not endorse and are not
                                        responsible or liable for any content, advertising, products or other materials on or available from
                                        such sites or resources. You further acknowledge and agree that we shall not be responsible or liable,
                                        directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
                                        use of or reliance on any such content, goods or services available on or through any such site or
                                        resource.</li>
                                </ol>
                            </li>
                            <li><b>ADDITIONAL TERMS; OWNERSHIP OF INTELLECTUAL PROPERTY</b>
                                <ol>
                                    <li>You are granted a limited, non-sub-licensable right to access the App and the Services for the purpose
                                        of enabling you to access the App or and/or the Services. Any use of the App and the Services is bound
                                        by the terms of this Agreement plus the following specific terms: <ol>
                                            <li>You will not use the App and/or avail the Services to create software that sends unsolicited
                                                communications (whether commercial or otherwise) to any third party;</li>
                                            <li>We reserve the right at any time to modify or discontinue, temporarily or permanently the App
                                                and/or the Services (or part thereof) with or without notice;</li>
                                            <li>Abuse or excessively frequent requests to the Services via the App may result in the temporary
                                                or permanent suspension of your account's access. We, in our sole discretion, will determine
                                                abuse or excessive usage;</li>
                                            <li>Philips does not warrant that the App will be compatible with your mobile device. Philips hereby
                                                grants you a non-exclusive, non-transferable, revocable right to use a compiled code copy of the
                                                App for one member account on one mobile device owned or leased solely by you, for your personal
                                                use. </li>
                                            <li>You acknowledge that Philips may from time to time issue upgraded versions of the App, and may
                                                automatically electronically upgrade the version of the App that you are using on your mobile
                                                device. You expressly consent to such automatic upgrading on your mobile device and agree that
                                                the terms and conditions of this Agreement and the Privacy Terms will apply to all such
                                                upgrades. Any third-party code that may be incorporated in the App is covered by the applicable
                                                open source or third party license EULA (end user license agreements), if any, authorizing use
                                                of such code and such license EULA would be incorporated herein by reference, to the extent
                                                applicable. The foregoing license grant is not a sale of the App or any copy thereof, and
                                                Philips, its Subsidiaries and Affiliates, or its third party partners or suppliers retain all
                                                right, title, and interest in the App (and any copy thereof). Any attempt by you to transfer any
                                                of the rights, duties or obligations hereunder, except as expressly provided for in this
                                                Agreement, is void. Philips and its Subsidiaries and Affiliates reserve all rights not expressly
                                                granted under this Agreement.</li>
                                        </ol>
                                    </li>
                                    <li>All content included on the App, such as text, graphics, logos, button icons, images, audio clips,
                                        digital downloads, data compilations and software, is our property, or the property of our Subsidiaries
                                        and Affiliates or content suppliers, and is protected by Indian and international intellectual property
                                        law, including copyright, authors’ rights and database rights laws. The compilation of all content on
                                        the App is our exclusive property and is protected by laws of India and international copyright and
                                        database right laws. All software used on the App is also our exclusive property, or the property of our
                                        affiliates or software suppliers, and is protected by Indian and international copyright and authors'
                                        rights laws.</li>
                                    <li>All right, title, and interest in and to the App and the Services (excluding your User Content) are and
                                        will remain the exclusive property of Philips, its Subsidiaries and Affiliates, and its licensors (as
                                        applicable). Any use of this App or its contents, including copying or storing it or them in whole or
                                        part, other than for your own personal and non-commercial use is prohibited without the written
                                        permission of Philips.</li>
                                    <li>Except as expressly provided in these terms of use, no part of Philips’ and/or its Subsidiaries’ and
                                        Affiliates’ intellectual property, including content or marks, logos, data, statistics, independent
                                        research conducted and posted by Philips may be copied, reproduced, republished, uploaded, posted,
                                        publicly displayed, encoded, translated, transmitted, or distributed in any way including mirroring to
                                        any other computer, server, website, or other medium for publication or distribution of any for any
                                        commercial enterprise, without Philips' prior written consent.</li>
                                    <li>By accepting the terms hereunder you agree that the Philips does not transfer/assign the title to the
                                        App to you, and nothing in these terms and conditions shall imply or be deemed or construed to mean that
                                        any right, title and interest (including but not limited to intellectual property rights) stands
                                        transferred/assigned to you by Philips, we retain the full and complete right, title and interest to the
                                        App, and all intellectual property, title and interest to the App, and all intellectual rights therein.
                                    </li>
                                    <li>Any usage of the App’s or Services’ contents, without the written authorization of Philips, shall be
                                        considered a breach of this Agreement, and you shall be required indemnify Philips for all liability
                                        incurred in this regard.</li>
                                    <li>User shall promptly report any violation of this clause to the Grievance Officer and shall take such
                                        further steps as may be reasonably requested to remedy any such violation and to prevent future
                                        violations.</li>
                                </ol>
                            </li>
                            <li><b>USER INFORMATION; LICENSE FROM YOU</b>
                                <ol>
                                    <li>Subject to Privacy Terms, the App and Services may collect from you, or you may provide, upload, create,
                                        transmit or display on the App while using the Services, certain data and other information, including
                                        biometric scans, data, documents, pictures and other information (“User Information”). Your use of the
                                        App and Services is predicated on such User Information, and any underlying issues in relation to the
                                        User Information may result in inaccuracy or inadequacy of the App and/or Services. You shall ensure
                                        that any updates to the User Information (including in relation to any change in medical condition) are
                                        duly provided to the App and Services at the earliest. You represent and warrant to Philips that (i)
                                        your User Information will not violate the legal rights or interests of any person or entity, and (ii)
                                        that you have the sole rights to, any and all User Information. You further agree that you are solely
                                        and exclusively: (i) responsible for all your User Information, and (ii) liable for the consequence of
                                        the User Information and your actions in relation thereto (including any loss or damage which Philips
                                        may suffer pursuant thereto). Your use of the App and the Services is entirely at your own risk,
                                        including Philips' usage of the User Information.</li>
                                    <li>You shall be responsible for monitoring the User Information and shall be liable to us for ensuring that
                                        the User Information provided to, transferred to, or handled by, or within, the App and Services does
                                        not infringe any third party rights.</li>
                                    <li>Whenever you make use of the Services (or any feature or part of the Services) that allows you to upload
                                        User Information on the App, you must comply with applicable data protection rules and laws. The User
                                        Information must be accurate where it states facts and comply with the law applicable in any location
                                        from which it is posted.</li>
                                    <li>You grant to Philips a perpetual, irrevocable, royalty free license to use your User Information, in
                                        each instance, as a whole or in any part, for any business purpose, including without limitation,
                                        commercialization, monetization, promotional, marketing, and training purposes, including providing or
                                        processing such User Information for data analytics. Pursuant to such license, Philips has the right and
                                        ability to appropriately monetise your User Information in such manner as it deems fit, subject to
                                        applicable law and the Privacy Terms. You confirm and warrant to Philips that you have all the rights,
                                        power and authority necessary to grant the above license. You understand that Philips, in performing the
                                        required technical steps to provide the Services to our users, may: (a) transmit or distribute your User
                                        Information over various networks and in various media; and (b) make such changes to your User
                                        Information as are necessary to conform and adapt that User Information to the technical requirements of
                                        connecting networks, devices, services or media. You agree that this license shall permit Philips to
                                        take these actions.</li>
                                    <li>You may remove or alter your User Information by deleting it, however in certain circumstances your User
                                        Information may not be completely removed, and copies of your User Information may continue to exist on
                                        the App and/or Services or in Philips' or its Subsidiaries' and Affiliates' records. We are not
                                        responsible or liable for the removal or deletion of (or failure to remove or delete) any of your User
                                        Information.</li>
                                    <li>We have implemented commercially reasonable technical and organizational measures designed to secure
                                        your personal information and User Information from accidental loss and from unauthorized access, use,
                                        use, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or
                                        use your personal information and User Information for improper purposes. You acknowledge that you
                                        provide your personal information and User Information at your own risk.</li>
                                </ol>
                            </li>
                            <li><b>BILLING AND PAYMENT</b>
                                <ol>
                                    <li>Certain aspects of the Services may be provided for a fee or other charge. If you elect to use paid
                                        aspects of the Services, you agree to the terms of sale, pricing, payment and billing policies
                                        applicable to such fees and charges. Philips may add new services for additional fees and charges, or
                                        amend fees and charges for existing services, at any time in its sole discretion.</li>
                                    <li>It is your responsibility to promptly provide Philips with any contact or billing information changes or
                                        updates (including phone number, email address, credit card numbers, etc.). Philips does not validate
                                        all credit card information required by the Customer's payment provider to secure payment.</li>
                                    <li>Should you choose to upgrade any of the Services provided, through in-app purchases, payment will be
                                        charged [to your credit/debit card, or through net banking, UPI]. [Any paid periodic subscription renews
                                        automatically unless cancelled at least 24 (twenty four) hours prior to the end of the subscription
                                        period.] </li>
                                    <li>You must notify Philips about any billing problems or discrepancies within 30 (thirty) days after
                                        charges first appear on your account statement. If it is not brought to Philips’s attention within 30
                                        (thirty) days, you agree to waive your right to dispute such problems or discrepancies.</li>
                                    <li>In respect of purchases made on the App, you hereby acknowledge and agree that no refund requests will
                                        be entertained in any manner whatsoever and no refunds will be initiated therefor.</li>
                                </ol>
                            </li>
                            <li><b>INDEMNITY AND LIMITATION OF LIABILITY</b>
                                <ol>
                                    <li>You agree to defend, indemnify and hold harmless Philips, its officers, directors, employees and agents,
                                        from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
                                        expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the
                                        Service and App; (ii) your violation of any term of this Agreement and/or the Privacy Terms; (iii) your
                                        violation of any third party right, including without limitation, any copyright, property, or privacy
                                        right; or (iv) any claim that your User Information caused damage to a third party. This defense and
                                        indemnification obligation will survive this Agreement and your use of the Service and App.</li>
                                    <li>The material displayed on our App is provided without any guarantees, conditions or warranties as to its
                                        accuracy. To the extent permitted by law, we, our Subsidiaries and Affiliates, and third parties
                                        connected to us hereby expressly exclude: <ol>
                                            <li>conditions, warranties and other terms, which might otherwise be implied by statute, common law
                                                or the law of equity; and</li>
                                            <li>any liability for any direct, indirect or consequential loss or damage incurred by any user in
                                                connection with the Services, our App or in connection with the use, inability to use, or
                                                results of the use of the Services or our App, any websites linked to it and any materials
                                                posted on it, including without limitation, any liability for: <ol>
                                                    <li>any personal harm or injury;</li>
                                                    <li>loss of business, profits or contracts;</li>
                                                    <li>loss of data;</li>
                                                    <li>loss of goodwill; and/or</li>
                                                    <li>any other loss or damage of any kind, however arising and whether caused by tort
                                                        (including negligence), breach of contract or otherwise, even if foreseeable or advised
                                                        of the possibility of the same.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><b>OFFENCES</b>
                                <ol>
                                    <li>You must not misuse our App and/or Services by knowingly introducing viruses, trojans, worms, logic
                                        bombs, time bombs, cancel bots, spyware or other material, computer programming routines, codes, files
                                        or such other programs which are malicious or technologically harmful, or limit the interests of rights
                                        of other users or limit the functionality of any computer software, hardware or telecommunications. You
                                        must not attempt to gain unauthorized access to our App and/or Services, the server on which our App is
                                        stored, or any server, computer or database connected to our site. You must not attack our App via a
                                        denial-of-service attack or a distributed denial-of service attack.</li>
                                    <li>By breaching this provision, you would commit a criminal offence under law, including without limitation
                                        under the Information Technology Act, 2000 (and any amendments or replacements thereof). We will report
                                        any such breach to the relevant law enforcement authorities, and we will co-operate with those
                                        authorities by disclosing your identity to them. In the event of such a breach, your right to use our
                                        App and Services will cease immediately.</li>
                                    <li>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses
                                        or other technologically harmful material that may infect your computer equipment, computer programs,
                                        data or other proprietary material due to your use of our App or to your downloading of any material
                                        posted on it, or on any App linked to it.</li>
                                </ol>
                            </li>
                            <li><b>GOVERNING LAW AND JURISDICTION</b>
                                <ol>
                                    <li>The jurisdictional courts of [Gurugram, Haryana] shall have sole jurisdiction over any claim arising
                                        from, or related to, a visit to / use of the App or the Services, although we retain the right to bring
                                        proceedings against you for breach of any of these terms and conditions in your location of residence,
                                        location of use, or other relevant location. The laws of India govern this Agreement and these terms and
                                        conditions of use of the App and Services, and the Privacy Terms.</li>
                                    <li>Philips accepts no liability whatsoever, direct or indirect, for noncompliance with the laws of any
                                        country other than that of India, the mere fact that App can be accessed or used or any facility can be
                                        availed of in a country other than India will not imply that we accede to the laws of such country.</li>
                                </ol>
                            </li>
                            <li><b>GRIEVANCE OFFICER</b>
                                <ol>
                                    <li>The name and contact details of our Grievance Officer are as follows: <br /><b>Ruchika Rai (Compliance
                                        Officer ISC)</b> <br /><b>ISC_Philipslegal@philips.com</b></li>
                                    <li>The Grievance Officer can be contacted to report abuse, or to complain against any content hosted,
                                        transmitted, published," updated or shared on the App or the Services. Philips will make commercially
                                        reasonable efforts to investigate and attempt to resolve such reports or complaints by reference to the
                                        principles contained in this Agreement and/or the Privacy Terms.</li>
                                </ol>
                            </li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default TermsConditionModal;
