import React from "react";
import "./sliderHeartPrint.css";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import HeartPrintMobile from '../../../assets/images/heartPrintMobile.svg'
import ClinicalGuidance from '../../../assets/images/clinicalGuidance.svg'
import ClinicalBackOffice from '../../../assets/images/clinicalBackOffice.svg'
import Heart from '../../../assets/images/heart.svg'
SwiperCore.use([Pagination]);

function SliderHeartPrint() {
  return (
    <div id="technology">
      <div id="sliderHeartPrint" className="max-width-layout">
        <h2 className="sliderHeartPrint-header">Track detailed insights with</h2>
        <h2 className="sliderHeartPrint-header">cutting edge technology</h2>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          className="swiper-container"
        >
          <SwiperSlide>
            <div className="row slider-container">
              <div className=" col-lg-6 col-xl-6  col-md-6 col-sm-12 col-12 m-0 slider-image">
                <img alt="Profiler" src={Heart} className="img-fluid w-50" />
              </div>
              <div className=" col-md-6 col-lg-6  col-xl-6  col-sm-12 col-12 m-0 slider-content">
                <h2>Heart Print</h2>
                <p>
                  Builds a longitudinal view of your heart's health utilizing digital biomarkers and imaging results to provide a current reflection of your heart's condition.
                </p>
                <p>
                  The heart print is made up of elemental health indices, from which we create micro health patterns that can be detected early, tracked for changes, and accumulated for deep insights.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row slider-container">
              <div className=" col-md-6 col-xl-6 col-lg-6 col-sm-12 col-12 m-0 slider-image">
                <img src={ClinicalGuidance} alt="Profiler" className="img-fluid" id="ClinicalGuidance" />
              </div>
              <div className=" col-lg-6 col-xl-6  col-md-6 col-sm-12 col-12 m-0 slider-content">
                <h2>Clinical & lifestyle guidance</h2>
                <p>
                  Our advanced machine learning and artificial intelligence algorithms are designed to monitor subclinical micro changes in health conditions such as Dyslipidemia, coronary artery disease, diabetes, and hypertension.
                </p>
                <p>
                  Utilizing personalized thresholds, the system engages users with relevant information and, when necessary, connects them with suitable wellness or clinical providers for expert advice and guidance.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row slider-container">
              <div className="col-xl-6  col-md-6 col-lg-6 col-sm-12 col-12 m-0 slider-image">
                <img alt="Profiler" src={HeartPrintMobile} className="img-fluid" id="heartPrintMobile" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-0 slider-content">
                <h2>Non-invasive & non-intrusive</h2>
                <p>
                  Our service leverages the capabilities of your smartphone to monitor your health, tailoring the measurements to align with your specific health condition.
                </p>
                <p>
                  We incorporate your diagnostic reports to automatically correlate your wellbeing with your clinical health status. Even with infrequent inputs from you, our system is designed to transform them into meaningful insights and actionable takeaways.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row slider-container">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-0 slider-image">
                <img src={ClinicalBackOffice} alt="Profiler" className="img-fluid w-50" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-0 slider-content">
                <h2>Clinical back-office support</h2>
                <p>
                  With your consent, our team of clinicians analyzes your anonymized data records to provide precise guidance.
                </p>
                <p>
                  We maintain a selection of back-office providers, enabling us to make informed decisions on your behalf, considering cost efficiency, expertise, and availability.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>


  );
}

export default SliderHeartPrint;