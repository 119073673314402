import React, { useState } from 'react';
import PhilipsLogo from '../../../assets/images/logo.png';
import './header.css';
import REACT_APP_CAD_CONSUMER_URL from '../utils/constants';


function Header(props: any) {
  const { path } = props;
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  
    const body = document.querySelector('body');
    if (body) {
      body.classList.toggle('mobile-nav-active');
    }
  
    const navbar = document.getElementById('navbar');
    if (navbar) {
      navbar.classList.toggle('navbar-left');
    }
  };
  return (
    <header id="header" className="header fixed-top" data-scrollto-offset="0">
      <div className="container-fluid d-flex align-items-center ">
        <a href="/" className="logo d-block align-items-center scrollto me-auto me-lg-0">
          <img src={PhilipsLogo} alt='Philips' className="img-fluid" />
        </a>
        <nav id="navbar" className={`navbar mb-3 ${isActive ? 'navbar-left' : ''}`} >
  <ul id="myList" className=" custom-ul d-flex align-items-start " >
    <li className="nav-link"><a className={path === '/' ? "active" : ""} href="/"><span>Home</span></a></li>
    <li className="nav-link"><a className={path === '/services' ? "active" : ""} href="/services"><span>Services</span></a></li>
    <i onClick={toggleClass} className={isActive ? 'bi mobile-nav-toggle bi-x' : 'bi mobile-nav-toggle bi-list d-none'}  ></i>
  </ul>
</nav>
        <a className="btn-getstarted scrollto" target="_self" href={`${REACT_APP_CAD_CONSUMER_URL}`} >Register</a>
      </div>
    </header>
  );
}

export default Header;
