import React from "react";
import "./privacyModal.css";


function PrivacyModal() {
    return (
        <div id="privacyModal" className="modal fade"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button  className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <h2 id="exampleModalLabel" className="modal-title fs-5" >PHILIPS GHS APP | PRIVACY TERMS</h2>
                        <p>PLEASE READ THESE PRIVACY TERMS CAREFULLY AND IN THEIR ENTIRETY, BEFORE ACCESSING OR USING THE GUIDED HEALTH SERVICE APP</p>
                        <p><ol>
                            <li>These Privacy Terms (""<b>Privacy Terms</b>"") describe Philips Global Business Services LLP 's (""<b>Philips</b>"") policies
                                and procedures on the collection, use and disclosure of the information provided by the end users and the
                                visitors (together referred to as the ""<b>Users</b>"") of the GUIDED HEALTH SERVICE application (""<b>App</b>"").
                                Every User who accesses or uses the App and any other related software and/or services with the characteristics
                                and features as described or available on the App (referred to collectively as the ""<b>Services</b>""), agrees
                                to be bound by the terms of these Privacy Terms. Philips may have subsidiaries and affiliated legal entities
                                around the world (""<b>Subsidiaries and Affiliates</b>""), providing the Services to Users on behalf of Philips.
                                Users acknowledge and agree that the Subsidiaries and Affiliates will be entitled to provide the Services and
                                shall have all rights and privileges equivalent to Philips hereunder, to the extent applicable. These Privacy
                                Terms and the terms and conditions hereunder represent a binding contract between Users and Philips with regard
                                to the App and in particular, the Services. Therefore, Users should read these Privacy Terms carefully and, in
                                their entirety, before starting to use the App and/or any Services. Users indicate, at their option, their
                                acceptance of and agreement to these Privacy Terms and the terms, policies, procedures, and conditions
                                hereunder, by clicking or tapping on a button indicating their acceptance of these Privacy Terms, or by
                                registering on the App and/or the Services or using them as a member or guest. </li>
                            <li>These Privacy Terms state the following: <ol>
                                <li>The type of information collected from the Users of the App, including Personal Information (as defined
                                    hereinafter);</li>
                                <li>The purpose, means and modes of usage of such information; and</li>
                                <li>How and to whom Philips will disclose such information.</li>
                            </ol>
                            </li>
                            <li>""<b>Personal Information</b>"" means any information that relates to a natural person, such as Users, and is
                                capable of identifying such person. Further, for the purpose of these Privacy Terms, ""<b>Sensitive Personal
                                    Data or Information</b>"" of a person to mean Personal Information about that person relating to, including
                                without limitation: <ol>
                                    <li>lifestyle, food preferences, medical conditions and family medical history, health goals or other
                                        fitness regimes;</li>
                                    <li>readings of vital signs, including without limitation, heart rate, blood pressure, breathing rate,
                                        height, weight;</li>
                                    <li>location unless location services are deactivated in the relevant section of the App;</li>
                                </ol>
                            </li>
                            <li><b>Privacy Statements</b>
                                <ol>
                                    <li>Users must note the following: <ol>
                                        <li>Any User that does not agree with any provisions of these Privacy Terms is required to leave the
                                            App immediately and discontinue any usage of the Services, and such User is not permitted to use
                                            this App. Philips will not be liable for any consequences arising from any unauthorized use.
                                        </li>
                                        <li>Except as otherwise provided herein, when browsing the App, a User is not required to provide
                                            Personal Information and/or Sensitive Personal Data or Information unless and until such User
                                            chooses to submit their Personal Information and/or Sensitive Personal Data or Information on
                                            the App.</li>
                                        <li>Philips may use cookies to help monitor Users online activity in relation to the App. Upon
                                            receiving specific consent from a User, Philips may share the data, which Philips collects from
                                            cookies, with our advertisement partners to track User visits, establish a Users non-personal
                                            identity and present such Users with targeted advertisements about our Services. </li>
                                        <li>The information that Philips collects and shares from the cookies is anonymous and not Personal
                                            Information and/or Sensitive Personal Data or Information.</li>
                                    </ol>
                                    </li>
                                    <li>A User can set or amend their device settings to delete or disable cookies. If a User chooses to disable
                                        cookies on their device, it may impair, degrade or restrict access to certain areas of the App. </li>
                                    <li>Philips has implemented Indian industry standard security policies, rules and technical measures to
                                        protect the personal data that it has under its control from unauthorised access, improper use or
                                        disclosure, unauthorised modification and unlawful destruction or accidental loss. It is expressly
                                        stated that Philips shall not be responsible for any breach of security or for any actions of any third
                                        parties that receive the Users’ personal data or events that are beyond the reasonable control of
                                        Philips including without limitation, acts of government, computer hacking, unauthorized access to
                                        computer data and storage device, computer crashes, breach of security and encryption, or any acts
                                        attributable to the Users or any third parties.</li>
                                    <li>The App may include hyperlinks to other websites or content or resources. Philips may not have control
                                        over any websites or resources, which are provided by other companies or persons. By accessing the App,
                                        each User acknowledges and agrees Philips is not responsible for any such external sites, content, or
                                        resources, and activities undertaken by them.</li>
                                    <li>Philips may disclose any Personal Information and/or Sensitive Personal Data or Information to
                                        governmental institutions or authorities if such disclosure is requisitioned under any law or judicial
                                        decree or when Philips, in its sole discretion, deems it necessary in order to comply with necessary
                                        legal requirements, protect its rights or the rights of others, to prevent harm to persons or property,
                                        to fight fraud and credit risk, or to enforce or apply these Privacy Terms or the terms of use relating
                                        to the App and Services (as may be amended or updated from time to time), available at Terms & Conditions.
                                    </li>
                                    <li>Philips addresses all discrepancies and grievances of all Users with respect to processing of
                                        information in a time bound manner, through the Grievance Officer. The details of the Grievance Officer
                                        are set out in Paragraph 8 below.</li>
                                    <li>Casual visitors must note the following: <ol>
                                        <li>No information is automatically collected by Philips from any casual visitors of this App, who
                                            are merely perusing or browsing the App, except as otherwise provided in Paragraphs 34(a)(iii)
                                            and 34(a)(iv) above.</li>
                                        <li>Nevertheless, certain provisions of these Privacy Terms are applicable to even such casual
                                            visitors, and such casual visitors are also required to read and understand the privacy
                                            statements set out herein, failing which they are required to leave the App immediately.</li>
                                        <li>If a casual visitor has inadvertently used or gone through this App prior to reading the privacy
                                            statements set out herein, and such casual visitor does not agree with the manner in which such
                                            information is obtained, stored or used, [merely deleting the App should ordinarily clear all
                                            temporary cookies installed by Philips]. However, Philips cannot guarantee, predict, or provide
                                            for, the behaviour of the App of all the visitors of the App.</li>
                                        <li>A User is not a casual visitor if such User has willingly submitted any Personal Information
                                            and/or Sensitive Personal Data or Information to Philips through any means, including through
                                            the App or via email. All such visitors will be deemed to be, and will be treated as, end-Users
                                            for the purposes of these Privacy Terms, and in which case, all the statements in these Privacy
                                            Terms shall apply to such persons.</li>
                                    </ol>
                                    </li>
                                    <li>Collection: <ol>
                                        <li>As part of the Services or through the App, Philips may collect, or User may provide, upload,
                                            create, transmit or display, including without limitation, the following Personal Information
                                            and/or Sensitive Personal Data or Information relating to the Users, which is necessary for the
                                            operation of the App and Services (""<b>User Information</b>""): <ol>
                                                <li>name;</li>
                                                <li>gender;</li>
                                                <li>age;</li>
                                                <li>contact information including location, email address and mobile number;</li>
                                                <li>readings of vital signs, including without limitation, heart rate, blood pressure,
                                                    breathing rate, height, weight;</li>
                                                <li>lifestyle, food preferences, medical conditions and family medical history;</li>
                                                <li>health goals or other fitness regimes;</li>
                                                <li>IP Address; and/or</li>
                                                <li>any other information as the User may provide.</li>
                                            </ol>
                                        </li>
                                        <li>The User Information specified above is collected, processed, transferred, and/or disclosed, for
                                            reasons considered lawful by Philips, including but not limited to:<ol>
                                                <li>Personalized Lifestyle Guidances</li>
                                                <li>personal identification of Users;</li>
                                                <li>customizing the content of the App and the Services to meet specific needs of the User;
                                                </li>
                                                <li>providing to Users all of the features and functionality of the App and the Services;
                                                </li>
                                                <li>tracking and analysing User preferences and trends in order to improve the quality and
                                                    design of the App and Services, and to create new features, promotions, functionality,
                                                    and services;</li>
                                                <li>monitoring the effectiveness of marketing campaigns, and monitor aggregate usage
                                                    metrics;</li>
                                                <li>remembering information so that Users will not have to re-enter it during their visit or
                                                    the next time they visit the App and/or Services;</li>
                                                <li>making improvements to the App, including by monitoring the use and behaviour of its
                                                    components so its performance, operation, maintenance and troubleshooting can be
                                                    improved;</li>
                                                <li>allowing Philips to contact the User for customer service and other purposes; and/or
                                                </li>
                                                <li>for any business purpose, including without limitation, commercialisation, monetisation,
                                                    promotional, marketing, and training purposes, including providing or processing such
                                                    User Information for data analytics.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                    </li>
                                    <li>Access to, and Processing of, User Information:<ol>
                                        <li>All User Information is maintained by Philips in electronic form on online servers/cloud systems
                                            and shall be accessible by certain personnel on behalf of Philips as mentioned below. The User
                                            Information may also be converted to physical form from time-to-time. Regardless of the manner
                                            of storage, Philips will keep all User Information confidential, and will only disclose User
                                            Information to the persons mentioned below:<ol>
                                                <li>its, or its Subsidiaries’ and Affiliates’ employees, directors, representatives, or
                                                    partners, on a need-to-know basis. All such personnel who have access to, and are
                                                    associated with the processing of User Information, are obliged to respect the
                                                    confidentiality of User Information.</li>
                                                <li>[Third party service providers to the extent necessary to provide the Users with the
                                                    Service. Philips may provide the User Information to third party service providers to
                                                    work on behalf of or with Philips to provide the Users with the Service, to help Philips
                                                    communicate with the Users or to maintain the App. Generally, the third party service
                                                    providers do not have any independent right to share this information, however certain
                                                    service providers who provide services on the App, including the providers of online
                                                    communications services, will have rights to use and disclose the User Information
                                                    collected in connection with the provision of these services in accordance with their
                                                    own privacy policies.]</li>
                                            </ol>
                                        </li>
                                        <li>Philips may also use and share certain User Information with reliable and reputed third-party
                                            payment gateway service providers which Philips may have engaged in order to ensure swift and
                                            comfortable payment mechanisms on the App and/or in relation to the Services. Such third-party
                                            payment gateway service providers and other payment transaction processors may have their own
                                            privacy policies in respect of the User Information Philips is required to provide to them for
                                            Users’ purchase-related transactions. For these providers, Users must read their privacy
                                            policies to understand the manner in which User Information (including financial information
                                            such as bank accounts, credit and debit card details or other payment instrument details) would
                                            be handled by these providers.</li>
                                        <li>For use of our services, Users may be redirected to third-party payment services provider. By
                                            proceeding with the payment, you acknowledge and agree that your payment details and personal
                                            information required for the transaction will be collected, processed, and stored by such
                                            third-party payment services provider.</li>
                                        <li>Notwithstanding the above, Philips shall not be responsible for any breach of security or for
                                            any actions of any third parties that receive the User Information or events that are beyond the
                                            reasonable control of Philips including without limitation, acts of government, computer
                                            hacking, unauthorized access to computer data and storage device, computer crashes, breach of
                                            security and encryption, or any acts attributable to the Users or any third parties.</li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><b>User Rights</b>
                                <ol>
                                    <li>Users have a right to opt-out of providing any of the User Information. However, in such event, Philips
                                        reserves the right to refuse or limit such Users’ access and use of the App and the Services, or part
                                        thereof.</li>
                                    <li>Users have the right to withdraw their consent to the use, disclosure, or processing, of any User
                                        Information. However, in such event, Philips reserves the right to refuse or limit such Users’ access
                                        and use of the App and the Services, or part thereof.</li>
                                    <li>Users have the right to update or correct User Information provided under the App. User may exercise
                                        such right by updating the profile section in the App</li>
                                    <li>User may remove their User Information from the App and Services by deleting it, however in certain
                                        circumstances such User Information may not be completely removed, and copies of such User Information
                                        may continue to exist on the App and/or Services or in Philips’ or its Subsidiaries’ and Affiliates’
                                        records. Philips or its Subsidiaries and Affiliates are not responsible or liable for the removal or
                                        deletion of (or failure to remove or delete) any User Information. Further, in such event, Philips
                                        reserves the right to refuse or limit such Users’ access and use of the App and the Services, or part
                                        thereof.</li>
                                    <li>Users have the right to unsubscribe from any commercial communication, which is not essential for
                                        Philips to either provide the App or Services to Users. Users may exercise such right by clicking on the
                                        relevant link to unsubscribe, available as part of such commercial communication.</li>
                                </ol>
                            </li>
                            <li><b>User Obligations</b>
                                <ol>
                                    <li>Users must, at all times, respect and follow the terms and conditions of the then-current Privacy Terms
                                        and the Terms of Use. This includes respecting all intellectual property rights which may belong to
                                        Philips, its Subsidiaries and Affiliates and/or any third parties.</li>
                                    <li>Any violation of these Privacy Terms by a User, directly or indirectly, may lead to the restriction,
                                        suspension or termination of a User account by Philips, and/or any other consequences as provided under
                                        the Terms of Use.</li>
                                    <li>A User’s usage of the App and Services is predicated on User Information, and any underlying issues in
                                        relation to the User Information may result in inaccuracy or inadequacy of the App and/or Services. Any
                                        User Information should not violate the legal rights or interests of any person or entity, and (ii) the
                                        relevant User should have the sole rights to, any and all User Information relating to such User. Users
                                        are solely and exclusively: (i) responsible for all their User Information, and (ii) liable for the
                                        consequence of their User Information and User actions in relation thereto (including any loss or damage
                                        which Philips may suffer pursuant thereto). A User’s usage of the App and the Services is entirely at
                                        User’s own risk, including Philips’ usage of the User Information.</li>
                                    <li>Users shall be responsible for all User Information provided by them, including the completeness,
                                        accuracy, and adequacy thereof, and shall be liable to Philips for ensuring that the User Information
                                        provided to, transferred to, or handled by, or within, the App and Services does not infringe any third
                                        party rights.</li>
                                    <li>Whenever a User makes use of the Services (or any feature or part of the Services) that allows a User to
                                        upload User Information on the App, such User must comply with applicable data protection rules and
                                        laws. The User Information must be accurate where it states facts and comply with the law applicable in
                                        any location from which it is posted.</li>
                                    <li>Users acknowledge that these Privacy Terms are to be read in conjunction with the Terms of Use. User’s
                                        visit and any dispute over privacy is subject to these Privacy Terms and the Terms of Use.</li>
                                </ol>
                            </li>
                            <li><b>Change in the Privacy Term</b>
                                <ol>
                                    <li>Philips may update these Privacy Terms at any time, including but not limited to, pursuant to any change
                                        in applicable laws, rules, and/or regulations, with or without advance notice. In the event there are
                                        significant changes in the way Philips treats User Information, Philips will display a notice on the App
                                        and/or send Users an email. Unless stated otherwise, these current Privacy Terms apply to all
                                        information that Philips has about the Users and their account, in relation to the App and Services.
                                    </li>
                                    <li>If a User uses the Service after a notice of change has been sent to such User or published on the App,
                                        such User hereby provides their consent to the changed practices.</li>
                                </ol>
                            </li>
                            <li><b>Grievance Office</b>
                                <ol>
                                    <li>The name and contact details of our Grievance Officer are as follows:<br /><b>Ruchika Rai (Compliance
                                        Officer ISC)</b><br /><b>ISC_Philipslegal@philips.com</b></li>
                                    <li>Any questions or concerns regarding the use or disclosure of User Information in relation to the App and
                                        Services should be directed to the Grievance Officer. Philips will make commercially reasonable efforts
                                        to investigate and attempt to resolve complaints and disputes regarding the use and disclosure of User
                                        Information by reference to the principles contained in these Privacy Terms.</li>
                                </ol>
                            </li>
                        </ol></p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PrivacyModal;
