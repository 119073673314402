import React from 'react';
import './App.css';
import Footer from './common/components/footer/footer';
import Header from './common/components/header/header';
import Home from './features/home/home';
import Services from './features/services/services';

function App() {
  let Component
  switch ((window.location.pathname)) {
    case "/":
      Component = Home
      break;
    case "/services":
      Component = Services
      break;
    default:
      Component = Home
      break;
  }
  return (<div className="container-fluid">
    
      <Header path={window.location.pathname}></Header>
      <div id='content' > <Component></Component></div>
      <div>
      <Footer></Footer></div>
      </div>
  );
}

export default App;
