import React from "react";
import "./timelineMobile.css";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import One from '../../../assets/images/one.svg';
import Two from '../../../assets/images/two.svg';
import Three from '../../../assets/images/three.svg';
import Four from '../../../assets/images/four.svg';
import Five from '../../../assets/images/five.svg';
import Six from '../../../assets/images/six.svg';
import Seven from '../../../assets/images/seven.svg';


function TimelineMobileProfiler() {
    return (
        <div id="timelineMobileProfiler" >
            <Timeline position="alternate" className="timelineMobile">
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Heart rate analysis</h3>
                        <p>Discover your resting heart rate and gain insights into its capacity for exertion</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: '#ffffff' }} />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={One} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Two} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Cardiorespiratory rhythm</h3>
                        <p>Uncover the efficiency of your body's self-healing capabilities, determined by the coordination between your heart and lungs </p>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Heart age</h3>
                        <p>Heart Age offers insight into how your heart's age compares to your chronological age, providing an understanding of potential cardiac risks </p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Three} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >

                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >

                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Four} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Resting heart energy</h3>
                        <p>Learn about your heart's capacity, which will indicate whether you have the energy reserves to increase your exertion levels​</p>
                    </TimelineContent>
                </TimelineItem>
               
                    
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Beats Report</h3>
                        <p>You will receive a personalized heart safety score with a comprehensive BEATS report​</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Five} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: '#ffffff' }} />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >

                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>

    );
}

export default TimelineMobileProfiler;