import React from 'react';
import './carousel.css';

function Carousel() {
    return (
        <div id="carousel-doctor" className="col-lg-12 max-width-layout">
            <div id="demo" className="carousel slide mt-5" data-bs-interval="false">
                <div className="col-12 d-flex justify-content-center mb-5">
                    <h2>Don't take our word for it</h2>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row">
                            <div className="col-md-12 pt-3 container">
                                <h5 className="mb-3">Mr. Rajesh Jha</h5>
                                <h6 className="mb-3">
                                    Co-founder and Director, Saarathi Healthcare
                                </h6>
                                <p>
                                    “Early guidance & secondary prevention can be shared between
                                    family physician and specialists as part of group practice,
                                    this will bring about 360O care for patient and timely action
                                    and references”{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-12 pt-3 container">
                                <h5 className="mb-3">Ms. Rujuta</h5>
                                <h6 className="mb-3">
                                    Chief Culture Officer, InCorp (Auditing Services)
                                </h6>
                                <p>
                                    “This is the most important topic that we have to deal, with
                                    so many employees facing cardiac issues, becoming aware of
                                    risks is very crucial”
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className=" container col-md-12 pt-3 ">
                                <h5 className="mb-3">Dr. Bhuvaneswaran</h5>
                                <h6 className="mb-3">
                                    Medical Director, PSG Hospital and research Group
                                </h6>
                                <p>
                                    “It's a very interesting solution and will work from someone
                                    who thinks they are fine to ones out of hospital. It can be
                                    offered to many sections of society”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-12 pt-3 container">
                                <h5 className="mb-3">Dr. G Rajendiran</h5>
                                <h6 className="mb-3">Head Cardiology PSG Medical Sciences</h6>
                                <p>
                                    “A very good digital outreach module for preventive
                                    cardiology”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className=" container col-md-12 pt-3 ">
                                <h5 className="mb-3">Dr. Madhavi</h5>
                                <h6 className="mb-3">
                                    Occupational Health Specialist, RINLH (PSU Steel Co)
                                </h6>
                                <p>
                                    “This is a very good solution to bring high level of
                                    awareness. It has very good use of it in workplaces”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-12 pt-3 container">
                                <h5 className="mb-3">Ms Sheela Anand</h5>
                                <h6 className="mb-3">Strategic Advisor – Insurance sector</h6>
                                <p>
                                    “This is a great proposition and Insurance Co’s, Brokers,
                                    TPA’s and corporates would have an adaptability to this. The
                                    solution has a lot of value and corporates will adopt it
                                    through Brokers/TPA’s”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-12 pt-3 container">
                                <h5 className="mb-3">Mr. Y. Reddy</h5>
                                <h6 className="mb-3">
                                    Plant Head – Apotex Research (Pharmaceuticals)
                                </h6>
                                <p>
                                    “The entire solution is so very simple, I love the way it
                                    works. Getting to know circulation, reactivity and more
                                    non-invasively is a very big step”.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
        </div>
    );
}

export default Carousel;
