import React, { useEffect } from 'react';
import AOS from 'aos';
import LandingPage from '../../assets/images/LandingPage.gif';
import Alarm from '../../assets/images/alarm.svg';
import Star from '../../assets/images/star.svg';
import Prescription from '../../assets/images/prescription.svg';
import Doctor from '../../assets/images/doctor.svg';
import Bicycle from '../../assets/images/bicycle.svg';
import TermsPrivacy from '../../assets/images/tandc.svg';
import ProfilerBanner from '../../assets/images/profilerJourneyTab.png';
import SignatureBanner from '../../assets/images/signatureJourneyTab.png';
import './home.css';
import Carousel from '../../common/components/carousel/carousel';
import SliderHeartPrint from '../../common/components/slider/sliderHeartPrint';
import Recognition from '../../common/components/recognition/recognition';
import REACT_APP_CAD_CONSUMER_URL from '../../common/components/utils/constants';


function Home() {
  useEffect(() => {
    AOS.init();
    let tabId = window.location.hash;
    if (tabId) {
      const el = document.createElement("a");
      el.href = tabId;
      el.click()
    }
  }, [])
  return (
    <>
      <section id="hero-animated" className="hero-animated max-width-layout">
  <div className="row no-gutters align-items-center justify-content-center" id="start">
    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12">
      <h2>Early guidance on heart safety</h2>
      <p>An early guidance solution designed to help monitor your heart health using just your smartphone, assist in early detection of potential health changes, provide insightful guidance, and suggest appropriate intervention at the right time.</p>
      <p>Heart is life, life is heart.</p>
      <div className="d-flex" id='register'>
        <a href={`${REACT_APP_CAD_CONSUMER_URL}`} target="_self" className="btn-get-started scrollto">Register</a>
      </div>
    </div>
    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 text-center">
      <img src={LandingPage} alt="Heart Print" className="img-fluid img-heart-safety" />
    </div>
  </div>
</section>

      <section id="promise" className="featured-services max-width-layout">
        <div className="section-header-promise text-center col-12 p-0 m-0 mt-3">
          <h2>The Philips Heartprint promise</h2>
        </div>
        <div className="row justify-content-center" id='box'>
          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out">
            <div className="service-item position-relative">
              <div className="icon col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={Alarm} alt="Alarm"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Give you early guidance about changes in heart health</p>
            </div>
          </div>

          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out" data-aos-delay="150">
            <div className="service-item position-relative">
              <div className="icon col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={Star} alt="Star"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Tells you how healthy your heart is and offers tips for improvement</p>
            </div>
          </div>

          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out" data-aos-delay="250">
            <div className="service-item position-relative">
              <div className="icon  col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={Prescription} alt="Prescription"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Prompts you to get health check-ups</p>
            </div>
          </div>

          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out" data-aos-delay="350">
            <div className="service-item position-relative">
              <div className="icon col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={Doctor} alt="Doctor"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Have a doctor look at your case for personalized guidance</p>
            </div>
          </div>

          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out" data-aos-delay="650">
            <div className="service-item position-relative">
              <div className="icon col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={Bicycle} alt="Bicycle"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Suggests lifestyle adjustments tailored to your needs</p>
            </div>
          </div>
          <div className="col-xl-2 col-md-4 col-sm-12 col-12 my-2 d-flex" data-aos="zoom-out" data-aos-delay="750">
            <div className="service-item position-relative">
              <div className="icon col-xl-12 col-md-12 col-sm-3 col-xs-3"><img src={TermsPrivacy} alt="T&C"
                className="img-fluid" /></div>
              <p className='col-xl-12 col-md-12 col-sm-9 col-xs-9'>Your data is safe, secure and in your control</p>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about max-width-layout">
        <div >
          <div className="section-header text-center col-12 p-0 m-0 mt-3">
            <h2>Your heart is under attack,</h2>
            <h2>are you listening to it?</h2>
          </div>
          <div className="row" >
            <div className="col-lg-12">
              <ul className="nav nav-pills text-center">
                <li><a className="nav-link active" data-bs-toggle="pill" href="#profiler">Profiler</a></li>
                <li><a className="nav-link" data-bs-toggle="pill" href="#signature">Signature</a></li>
              </ul>
              <div className="tab-content tab-content-journey">
                <div className="tab-pane fade show active col-12" id="profiler">
                  <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex p-0 m-0'>
                      <img src={ProfilerBanner} alt="Profiler" className='img-fluid w-100' />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 about-desc p-4 m-0'>
                      <h2>Profiler Journey</h2>
                      <p>The profiler observes your resting heart over 5 days. By analysing your heart’s patterns, it aims to reveal insights into different facets of your health such as:</p>
                      <ul className='mb-5'>
                        <li>Resting heart safety score</li>
                        <li>Progression in your overall health</li>
                        <li>Indicators of changes in your overall health</li>
                        <li>Resilience & reliability metrics</li>
                      </ul>
                      <a className="btn-learn" href="/services#profiler">Learn more</a>
                    </div>

                  </div>
                </div>
                <div id="signature" className="tab-pane fade show" >
                  <div className='row'>
                  <div className=' col-md-6 col-sm-12 col-xl-6 col-lg-6  col-12 d-flex p-0 m-0'>
                      <img src={SignatureBanner} className='img-fluid w-100'  alt="Profiler"/>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xl-6  col-12 about-desc p-4 m-0'>
                      <h2>Signature Journey</h2>
                      <p>The Signature journey analyses your reactive heart over 8 days. By correlating your resting heart and reactive heart parameters, it will reveal important aspects of your heart during exertion like:</p>
                      <ul className='mb-5'>
                        <li>Reactive heart safety score</li>
                        <li>Endurance & fitness</li>
                        <li>Burnout potential</li>
                      </ul>

                      <a className="btn-learn" href="/services#signature">Learn more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="slider" className="max-width-layout"> <SliderHeartPrint></SliderHeartPrint></section>
      <section id="recongnition" className="max-width-layout">  <Recognition></Recognition></section>
      <section id="carousel" className="max-width-layout">  <Carousel></Carousel></section>
    </>
  );
}

export default Home;