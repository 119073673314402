import React from "react";
import "./timeline.css";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import One from '../../../assets/images/one.svg';
import Two from '../../../assets/images/two.svg';
import Three from '../../../assets/images/three.svg';
import Four from '../../../assets/images/four.svg';
import Five from '../../../assets/images/five.svg';
import Six from '../../../assets/images/six.svg';
import Seven from '../../../assets/images/seven.svg';
import Eight from '../../../assets/images/eight.svg';


function TimelineSignature() {
    return (
        <div id="timelineProfiler" >
            <Timeline position="alternate">
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Cardiac Index</h3>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: '#ffffff' }} />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={One} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >
                        <p>We determine whether your heart is pumping blood efficiently to maintain your hemodynamic stability</p>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >
                        <p>We evaluate whether your blood pressure is adequately controlled to ensure your blood flow remains regulated, a vital aspect of your heart health​</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Two} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Blood pressure analysis</h3>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Vo2</h3>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Three} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >
                        <p>Uncover your peak aerobic capacity, indicating the maximum volume of oxygen your body can use during high-intensity exerciseh</p>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >
                        <p>The profiler journey provides insights into your resting heart energy. Now, learn about your active heart energy, which reveals how efficiently your heart uses energy during physical activity</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img className='numbers-icon' src={Four} alt="Profiler" />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Reactive heart Energy</h3>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Stress indicator</h3>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot className="timeline-dot" variant="outlined" >
                            <img alt="Profiler" className='numbers-icon' src={Five}  />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >
                        <p>We analyse the subtle variations in your heart's behaviour under stress to determine your tolerance levels to both mental and physical stress</p>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >
                        <p>Analyzing your heart's behaviour over the journey helps us determine if you experience latent anxiety and whether it's impacting your body</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Six} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Anxiety indicator</h3>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-opposite-content"
                    >
                        <h3>Depression indicator</h3>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Seven} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector className="timeline-connector" />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-content" >
                        <p>We identify early signs in your mind and body that may lead to depression in the future and provide guidance on how to address them</p>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem className="timeline-item">
                    <TimelineOppositeContent
                        className="timeline-content"
                    >
                        <p>We integrate your resting and reactive heart patterns to create a comprehensive heart blueprint. This includes conducting over 40 checks for irregularities, offering tailored recommendations, and providing a detailed report that offers a clear view of your heart health, complete with actionable guidance.</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector className="timeline-connector" />
                        <TimelineDot variant="outlined" className="timeline-dot">
                            <img src={Eight} alt="Profiler" className='numbers-icon' />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: '#ffffff' }} />
                    </TimelineSeparator>
                    <TimelineContent className="timeline-opposite-content">
                        <h3>Beats report</h3>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>

    );
}

export default TimelineSignature;